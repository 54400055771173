<template>
  <PageWrapDefault>
    <div
      class="flex flex-col items-center justify-center h-screen w-full pb-48"
    >
      <div>
        <div
          class="flex justify-center items-center mb-12 text-9xl text-green-400"
        >
          <font-awesome-icon :icon="['fal', 'check']" class="text-20xl" />
        </div>
        <p class="text-2xl max-w-lg">
          Your Personal Liability Policy Application has been sent successfully.
          Our administrators will get in touch with you after it has been
          reviewed
        </p>
      </div>
    </div>
  </PageWrapDefault>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "submission-success"
});
</script>
