import { checkIfIsAtlasCompany } from "../forms/utils/quotes";
import {
  IPersonalLiabilityPaymentLimit,
  PersonalLiabilityPaymentLimitArrForAtlas,
  PersonalLiabilityPaymentLimitArrForColonial
} from "../store/modules/personalLiabilityPolicy/types";

export function getPersonalLiabilityLimits(
  companyNumber: number | ""
): {
  label: IPersonalLiabilityPaymentLimit;
  value: IPersonalLiabilityPaymentLimit;
}[] {
  let limits = PersonalLiabilityPaymentLimitArrForAtlas;
  if (companyNumber && !checkIfIsAtlasCompany([companyNumber]))
    limits = PersonalLiabilityPaymentLimitArrForColonial;
  return limits.map(limit => ({
    label: limit,
    value: limit
  }));
}
