import { FormBlock } from "@/components/FormBuilder/types";
import {
  useFormSection,
  useFormFieldGroup,
  useTextField,
  useField
} from "@/components/FormBuilder/Helpers";
import {
  InsuredLocationStatusArr,
  riskDeclineNoQuestionArr,
  riskDeclineYesQuestionArr
} from "@/store/modules/personalLiabilityPolicy/types";
import { validateSchema } from "@/helpers/validateSchema";
import { addressForm, mailingAddressForm } from "./address";
import { getPersonalLiabilityLimits } from "../../helpers/personalLiabilityLimits";

export const personalLiabilityPolicyQuestions = (
  companyNumber: number | ""
): any => [
  useField(
    {
      key: "personalLiabilityLimit",
      type: "radio_button_group",
      required: true,
      preamble: "Personal Liability/Medical Payment Limits",
      options: getPersonalLiabilityLimits(companyNumber)
    },
    ["my-12 text-base"]
  ),
  useField(
    {
      key: "insuredLocationStatus",
      type: "radio_button_group",
      required: true,
      preamble: "Is the insured location ...",
      options: InsuredLocationStatusArr.map((status: string) => ({
        label: status,
        value: status
      }))
    },
    ["my-12 text-base"]
  ),
  useField(
    {
      key: "businessOrDayCareAtLocation",
      type: "radio_button_group",
      required: true,
      preamble:
        "Does the occupant of the insured location operate a business or day care at the insured location?",
      options: ["YES", "NO"].map((status: string) => ({
        label: status,
        value: status
      }))
    },
    ["my-12 text-base"]
  ),
  useField({
    key: "approvalCode",
    label: "Please enter underwriter approval  code.",
    required: true,
    type: "text",
    conditions: {
      and: [
        {
          operator: "equals",
          field: "businessOrDayCareAtLocation",
          value: "YES"
        }
      ]
    }
  }),
  useField(
    {
      key: "smokeDetectorsAtLocation",
      type: "radio_button_group",
      required: true,
      preamble: "Does the insured location have smoke detectors installed?",
      options: ["YES", "NO DECLINE RISK"].map((status: string) => ({
        label: status,
        value: status
      }))
    },
    ["my-12 text-base"]
  ),
  useField(
    {
      key: "swimmingPoolAtLocation",
      type: "radio_button_group",
      required: true,
      preamble: "Is there a swimming pool at the insured location?",
      options: ["YES", "NO"].map((status: string) => ({
        label: status,
        value: status
      }))
    },
    ["my-12 text-base"]
  ),
  useField(
    {
      key: "poolFencedToLocalBuildingCode",
      type: "radio_button_group",
      required: true,
      preamble: "Is the pool fenced to local building code?",
      options: ["YES", "NO DECLINE RISK"].map((status: string) => ({
        label: status,
        value: status
      })),
      conditions: {
        and: [
          {
            field: "swimmingPoolAtLocation",
            value: "YES",
            operator: "equals"
          }
        ]
      }
    },
    ["my-12 text-base"]
  ),
  useField(
    {
      key: "poolHasDivingBoardOrSlide",
      type: "radio_button_group",
      required: true,
      preamble: "Does the pool have a diving board or slide?",
      options: ["YES", "NO"].map((status: string) => ({
        label: status,
        value: status
      })),
      conditions: {
        and: [
          {
            field: "swimmingPoolAtLocation",
            value: "YES",
            operator: "equals"
          }
        ]
      }
    },
    ["my-12 text-base"]
  ),
  useField(
    {
      key: "viciousAnimalAtLocation",
      type: "radio_button_group",
      required: true,
      preamble:
        "Are there any vicious dogs, dangerous or exotic breeds of animals kept at the insured location?",
      options: riskDeclineYesQuestionArr.map((status: string) => ({
        label: status,
        value: status
      }))
    },
    ["my-12 text-base"]
  ),
  useField(
    {
      key: "locationHasWrittenAtlasTdpPolicy",
      type: "radio_button_group",
      required: true,
      preamble:
        "Is this insured location currently written on an Atlas TDP policy?",
      options: riskDeclineNoQuestionArr.map((status: string) => ({
        label: status,
        value: status
      }))
    },
    ["my-12 text-base"]
  ),
  useTextField(
    {
      key: "atlasTdpPolicyNumber",
      required: true,
      label: "Atlas TDP policy number",
      preamble: "Atlas TDP policy number",
      conditions: {
        and: [
          {
            field: "locationHasWrittenAtlasTdpPolicy",
            value: "YES",
            operator: "equals"
          }
        ]
      }
    },
    ["my-12 text-base"]
  )
];

export const personalLiabilityPolicyForm = (
  companyNumber: number | "",
  isAdmin: boolean = false
): FormBlock[] => [
  ...useFormSection(
    [
      ...useFormFieldGroup(
        [
          useTextField({
            key: "agentCode",
            label: "Agent Code",
            placeholder: "",
            required: true
          }),
          useField({
            type: "phone",
            key: "phone",
            label: "Phone",
            required: true
          }),
          useTextField({
            key: "fax",
            label: "Fax",
            placeholder: "Fax",
            required: false
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup(
        [
          useTextField({
            key: "agencyName",
            label: "Agency Name",
            placeholder: "Agency Name",
            required: true
          }),
          useField({
            key: "effectiveDate",
            label: "Effective Date",
            placeholder: "Effective Date",
            required: true,
            type: "date"
          }),
          useField({
            key: "expiryDate",
            label: "Expiry Date",
            placeholder: "Expiry Date",
            required: true,
            type: "date",
            disabled: !isAdmin
          })
        ],
        { layout: "3-col" }
      ),
      ...useFormFieldGroup(
        [
          useTextField({
            key: "applicantName",
            label: "Applicant Name",
            placeholder: "Applicant Name",
            required: true
          }),
          useTextField({
            key: "spouseName",
            label: "Spouse Name",
            placeholder: "Spouse Name",
            required: false
          })
        ],
        { layout: "2-col" }
      ),
      ...useFormFieldGroup(
        [
          useField({
            key: "companyNumber",
            label: "Company",
            required: true,
            type: "select"
          })
        ],
        { layout: "2-col" }
      ),
      useField({
        key: "agencyMailingAddress",
        label: "Agency Mailing Address",
        required: true,
        type: "address",
        addressType: "mailingAddress",
        isPOBOXMailingAddress: "true"
      }),
      useField({
        key: "locationOfProperty",
        label: "Location of property",
        required: true,
        type: "address",
        addressType: "physicalAddress",
        isPOBOXMailingAddress: "false",
        validations: [
          {
            method: "custom",
            error: "Property Address is Invalid",
            customValidator: (value: any) => {
              return validateSchema(
                addressForm({
                  disableState: false,
                  addressType: "physicalAddress"
                }),
                value
              ).isValid;
            }
          }
        ]
      }),
      useField({
        key: "applicantMailingAddress",
        label: "Applicant Mailing Address",
        required: true,
        type: "address",
        addressType: "mailingAddress",
        isPOBOXMailingAddress: "true",
        allowSyncingWithPhysicalAddress: true,
        validations: [
          {
            method: "custom",
            error: "Mailing Address is invalid",
            customValidator: (value: any) => {
              return validateSchema(mailingAddressForm, value).isValid;
            }
          }
        ],
        conditions: {
          and: [
            {
              field: "locationOfProperty.houseNumber",
              value: true,
              operator: "exists"
            },
            {
              field: "locationOfProperty.streetName",
              value: true,
              operator: "exists"
            },
            {
              field: "locationOfProperty.city",
              value: true,
              operator: "exists"
            },
            {
              field: "locationOfProperty.state",
              value: true,
              operator: "exists"
            },
            {
              field: "locationOfProperty.zipCode",
              value: true,
              operator: "exists"
            }
          ]
        }
      })
    ],
    {
      title: "Applicant & Agent Details",
      description: ""
    }
  ),
  ...useFormSection(personalLiabilityPolicyQuestions(companyNumber), {
    title: "Application Questionnaire",
    description:
      "Please complete the following questions before submitting this application"
  }),
  useField(
    {
      type: "checkbox",
      label: ["fields", "agentAttest"],
      required: true,
      key: "acknowledgementOfInsuredQuestioning"
    },
    ["my-12 text-base"]
  )
];
