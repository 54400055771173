
import Vue from "vue";
import { validateFields } from "@/helpers";
import { personalLiabilityPolicyForm } from "@/forms/shared/personalLiabilityPolicy";
import { FormBlock } from "@/components/FormBuilder/types";
import {
  personalLiabilityPolicyMapActions,
  personalLiabilityPolicyMapGetters,
  personalLiabilityPolicyMapMutations,
  personalLiabilityPolicyMapState
} from "@/store/modules/personalLiabilityPolicy";
import { authMapState } from "@/store/modules/auth";
import { objectDifference } from "@/helpers";
import PersonalLiabilityPolicyMixin from "./PersonalLiabilityPolicyMixin.vue";
import { get } from "lodash";

interface IEditPLApplication {
  errorMessage: string;
  successMessage: string;
  isLoading: boolean;
  disablePrimaryButton: boolean;
  personalLiabilityPolicyForm: FormBlock[];
  loadingText: string;
}
export default Vue.extend({
  name: "EditPLApplication",
  mixins: [PersonalLiabilityPolicyMixin],
  data(): IEditPLApplication {
    return {
      errorMessage: "",
      successMessage: "",
      isLoading: false,
      personalLiabilityPolicyForm: [],
      disablePrimaryButton: true,
      loadingText: ""
    };
  },
  created() {
    if (this.$route.params.id) {
      this.isLoading = true;
      this.getPersonalLiabilityPolicy(this.$route.params.id as string).then(
        () => (this.isLoading = false)
      );
    } else {
      //go back to list
      this.$appNotifyError("No Personal Liability Policy ID provided");
      this.toolbarSelectItem("cancel");
    }
  },
  destroyed() {
    this.SET_EDIT(" " as any);
  },
  computed: {
    ...personalLiabilityPolicyMapState(["editing"]),
    ...personalLiabilityPolicyMapGetters(["getPersonalLiabilityPolicyById"]),
    ...authMapState(["user"]),
    disableSubmitButton(): boolean {
      return (
        this.disablePrimaryButton ||
        Object.keys(this.updatedFields).length === 0 ||
        (!!this.editing && !this.editing?.acknowledgementOfInsuredQuestioning)
      );
    },
    original(): any {
      return this.getPersonalLiabilityPolicyById(
        this.$route.params.id as string
      );
    },
    updatedFields(): any {
      return objectDifference(this.editing, this.original);
    },
    updatePolicyData(): FormBlock[] {
      const companyNumber = get(this.editing, "companyNumber", "");
      return personalLiabilityPolicyForm(companyNumber);
    }
  },
  methods: {
    ...personalLiabilityPolicyMapActions([
      "getPersonalLiabilityPolicy",
      "updatePersonalLiabilityPolicy"
    ]),
    ...personalLiabilityPolicyMapMutations({
      ["SET_EDIT"]: "SET_EDIT"
    }),
    async toolbarSelectItem(event: string) {
      switch (event) {
        case "update":
          await this.submit();
          break;
        case "cancel":
          this.$router.push("/personal-liability-policies").catch(() => {});
          break;
      }
    },
    async submit(): Promise<void> {
      try {
        this.isLoading = true;
        const refs = this.$refs;
        const isValid = validateFields(refs);
        if (isValid && this.editing && this.editing._id) {
          this.loadingText =
            "Updating Personal Liability Policy. Please wait...";
          await this.updatePersonalLiabilityPolicy({
            id: this.editing._id,
            update: this.updatedFields
          });
          this.successMessage =
            "Personal Liability Policy updated successfully";
          const redirectUrl = "/personal-liability-policies";
          this.$router.replace(redirectUrl);
          this.SET_EDIT({} as any);
        } else {
          throw new Error(
            "Form invalid. Please check the fields for missing values."
          );
        }
      } catch (err) {
        this.errorMessage = err.message;
        this.$bugSnagClient.notify(err);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    },
    validationChangeHandler(e: any) {
      const { formIsValid } = e;
      this.disablePrimaryButton = !formIsValid;
    }
  }
});
