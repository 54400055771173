var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"errorMessage":_vm.errorMessage,"successMessage":_vm.successMessage,"topActionButtonPrimary":{
    text: 'Submit',
    key: 'create',
    disabled: _vm.disableSubmitButton
  },"topActionButtonSecondary":{
    text: 'Cancel',
    key: 'cancel'
  },"element-loading-text":_vm.loadingText},on:{"toolbarSelectItem":_vm.toolbarSelectItem,"errorMessageClosed":function($event){_vm.errorMessage = ''},"successMessageClosed":function($event){_vm.successMessage = ''}}},[_c('form-builder',{ref:"formElement",attrs:{"schemas":_vm.updatePolicyData,"formValues":_vm.editing || {},"dynamicOptions":{
      companyNumber: _vm.getCompanies
    }},on:{"formFieldChanged":_vm.formFieldChangeHandler,"validationChanged":_vm.validationChangeHandler}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }