
import Vue from "vue";
import AddressViewer from "@/views/admin/personalLiabilityPolicy/SinglePersonalLiabilityPolicy/AddressViewer.vue";
import { format } from "date-fns";
import { LEGACY_PHP_API_DATE_FORMAT } from "@/helpers/constants";
import { authMapGetters } from "@/store/modules/auth";
export default Vue.extend({
  name: "Personal-Liability-Policy-Application-View",
  components: { AddressViewer },
  props: {
    application: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data() {
    const poolAnswers = [];
    if (this.application.swimmingPoolAtLocation === "YES") {
      poolAnswers.push(
        ...[
          {
            question: "Is the pool fenced to local building code?",
            answer: this.application.poolFencedToLocalBuildingCode
          },
          {
            question: "Does the pool have a diving board or slide?",
            answer: this.application.poolHasDivingBoardOrSlide
          }
        ]
      );
    }
    return {
      answers: [
        {
          question: "Personal Liability/Medical Payment Limits",
          answer: this.application.personalLiabilityLimit
        },
        {
          question: "Insured location is ....",
          answer: this.application.insuredLocationStatus
        },
        {
          question:
            "Does the occupant of the insured location operate a business or day care at the insured location?",
          answer: this.application.businessOrDayCareAtLocation
        },
        {
          question: "Does the insured location have smoke detectors installed?",
          answer: this.application.smokeDetectorsAtLocation
        },
        {
          question: "Is there a swimming pool at the insured location?",
          answer: this.application.swimmingPoolAtLocation
        },
        ...poolAnswers,
        {
          question:
            "Are there any vicious dogs, dangerous or exotic breeds of animals kept at the insured location?",
          answer: this.application.viciousAnimalAtLocation
        },
        {
          question:
            "Is this insured location currently written on an Atlas TDP policy?",
          answer: this.application.locationHasWrittenAtlasTdpPolicy
        },
        {
          question: "Atlas TDP policy number",
          answer: this.application.atlasTdpPolicyNumber
        },

        {
          question: this.__getText("fields", "agentAttest"),
          answer: "YES"
        }
      ]
    };
  },
  methods: {
    getFormattedDate(date: string) {
      if (date && date.length > 0) {
        return format(new Date(date), LEGACY_PHP_API_DATE_FORMAT);
      } else {
        return "N/A";
      }
    }
  },
  computed: {
    ...authMapGetters(["getCompanyByCompanyNumber"])
  }
});
