
import Vue from "vue";
import {
  isExemptedUserRole,
  removeEmptyFields,
  validateFields
} from "@/helpers";
import { personalLiabilityPolicyForm } from "@/forms/shared/personalLiabilityPolicy";
import { FormBlock } from "@/components/FormBuilder/types";
import {
  personalLiabilityPolicyMapActions,
  personalLiabilityPolicyMapMutations,
  personalLiabilityPolicyMapState
} from "@/store/modules/personalLiabilityPolicy";
import { get, set } from "lodash";
import { defaultAddressStructure } from "@/helpers/defaultObjects";
import { authMapState } from "@/store/modules/auth";
import PersonalLiabilityPolicyMixin from "./PersonalLiabilityPolicyMixin.vue";

interface ICreatePLApplication {
  errorMessage: string;
  successMessage: string;
  isLoading: boolean;
  disablePrimaryButton: boolean;
  disableRiskButton: boolean;
  personalLiabilityPolicyForm: FormBlock[];
  loadingText: string;
}
export default Vue.extend({
  name: "CreatePLApplication",
  mixins: [PersonalLiabilityPolicyMixin],
  data(): ICreatePLApplication {
    return {
      errorMessage: "",
      successMessage: "",
      isLoading: false,
      disablePrimaryButton: true,
      disableRiskButton: true,
      personalLiabilityPolicyForm: [],
      loadingText: ""
    };
  },
  created() {
    let defaultData: any = {};
    if (
      this.$getCurrentUser.role === "agent" ||
      this.$getCurrentUser.role === "agencyAdmin" ||
      this.$getCurrentUser.role === "subAdmin"
    ) {
      defaultData = {
        agentCode: this.$getCurrentUser.agentCode,
        phone: get(this.$getCurrentUser, "phone.primary"),
        agencyName: get(this.$getCurrentUser, "agencyData.agencyName")
      };
    } else if (this.user) {
      defaultData = {
        agentCode: get(this.user, "agentCode"),
        phone: get(this.user, "phone.primary")
      };
    }
    const agencyMailingAddress =
      get(this.$getCurrentUser, "agencyData.mailingAddress", {}) || {};
    const defaultAgencyMailingAddress = Object.assign(defaultAddressStructure, {
      isSameAsPhysicalAddress: false
    });
    set(
      defaultData,
      "agencyMailingAddress",
      agencyMailingAddress ? agencyMailingAddress : defaultAgencyMailingAddress
    );
    set(
      defaultData,
      "agencyName",
      get(this.$getCurrentUser, "agencyData.agencyName", "") || ""
    );

    this.SET_EDIT(defaultData);
  },
  destroyed() {
    this.SET_EDIT(" " as any);
  },
  computed: {
    ...personalLiabilityPolicyMapState(["editing"]),
    ...authMapState(["user"]),
    disableSubmitButton() {
      if (
        this.editing?.acknowledgementOfInsuredQuestioning &&
        !this.disablePrimaryButton &&
        !this.disableRiskButton
      ) {
        return false;
      }
      return true;
    },
    updatePolicyData(): FormBlock[] {
      const companyNumber = get(this.editing, "companyNumber", "");
      return personalLiabilityPolicyForm(
        companyNumber,
        isExemptedUserRole(this.$getCurrentUser?.role)
      );
    }
  },
  methods: {
    ...personalLiabilityPolicyMapActions(["createPersonalLiabilityPolicy"]),
    ...personalLiabilityPolicyMapMutations({
      ["SET_EDIT"]: "SET_EDIT"
    }),
    async toolbarSelectItem(event: string) {
      switch (event) {
        case "create":
          await this.submit();
          break;
        case "cancel":
          this.$router.push("/personal-liability-policies").catch(() => {});
          break;
      }
    },
    async submit(): Promise<void> {
      try {
        const refs = this.$refs;
        const isValid = validateFields(refs);
        if (isValid) {
          this.isLoading = true;
          this.loadingText =
            "Creating Personal Liability Policy. Please wait...";
          await this.createPersonalLiabilityPolicy(
            removeEmptyFields(this.editing as any)
          );
          this.successMessage =
            "Personal Liability Policy created successfully";
          const redirectUrl = "/personal-liability-policies";
          this.$router.replace(redirectUrl);
          this.SET_EDIT({} as any);
        } else {
          throw new Error(
            "Form invalid. Please check the fields for missing values."
          );
        }
      } catch (err) {
        this.errorMessage = err.message;
        this.$bugSnagClient.notify(err);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    },
    validationChangeHandler(e: any) {
      const { formIsValid } = e;
      this.disablePrimaryButton = !formIsValid;
    }
  }
});
